import axios from 'axios'

import auth from '../../auth/authUtil'

// A client-side logger

export default class Logger {
  constructor () {
    this.messages = []
  }

  flush () {
    const pkg = {
      messages: this.messages,
      userAgent: window.navigator.userAgent
    }
    axios.post(`${process.env.BASE_API_URL}/api/logs`, pkg, auth.requestConfig()).then(() => {
      this.messages = []
    })
  }

  error (stack) {
    this.messages.push({
      level: 'error',
      message: stack
    })
    // Do we need to be smarter about flushing? Eventually.
    this.flush()
  }

  bi (objectId, objectName, actionId) {
    this.messages.push({
      level: 'bi',
      meta: {
        clientEventObjectID: objectId,
        clientEventObjectName: objectName,
        clientEventActionID: actionId,
        clientEventTimestamp: Date.now()
      }
    })
    // Do we need to be smarter about flushing? Probably soon.
    this.flush()
  }
}
