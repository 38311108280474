import { Button } from '@smartsheet/lodestar-core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { preview } from '../common/SmartfillApi'
import { selectSheet, selectSheetRowIds } from '../common/store/app'
import { setError } from '../common/store/error'
import {
  clearCurrentMapping,
  saveCurrentMapping,
  selectCurrentMapping
} from '../common/store/mapping'
import {
  MAPPING_TYPES,
  getMappingType,
  getPairingList,
  isReadyToSave
} from '../common/util/mapping'
import DocumentViewer from './DocumentViewer'
import EmailPreviewer from './EmailPreviewer'
import OutputNameField from './OutputNameField'
import ReplaceModal from './ReplaceModal'
import SettingsMenu from './SettingsMenu'

import { APP_DOMAIN } from '../common/constant'

import './DocHandler.css'

const DocHandler = ({
  setFieldPairing,
  setOutputNameColumn,
  setIsWizardOpen,
  setIsEditorOpen
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const sheet = useSelector(selectSheet)
  const rowIds = useSelector(selectSheetRowIds)
  const currentMapping = useSelector(selectCurrentMapping)

  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false)
  const [isSavingMapping, setIsSavingMapping] = useState(false)
  const [emailPreviewerOpen, setEmailPreviewerOpen] = useState(false)
  const [replaceModalIsOpen, setReplaceModalIsOpen] = useState(false)

  const saveAndExit = () => {
    setIsSavingMapping(true)
    dispatch(saveCurrentMapping())
      .unwrap()
      .then(() => {
        dispatch(clearCurrentMapping())
        setIsEditorOpen(false)
      })
      .catch((error) => {
        dispatch(setError(error))
        setIsSavingMapping(false)
      })
  }

  const getPreviewRow = () => {
    if (rowIds.length) {
      return sheet.rows.find((row) => row.id === rowIds[0])
    }
    return sheet.rows[0]
  }

  const showPreview = () => {
    const previewRow = getPreviewRow()
    setIsGeneratingPreview(true)
    preview(currentMapping, [previewRow.id])
      .then(
        (resp) =>
          resp.data.filePath &&
          window.open(
            `${APP_DOMAIN}${resp.data.filePath}`,
            '_blank'
          )
      )
      .catch((error) => dispatch(setError(error)))
      .finally(() => setIsGeneratingPreview(false))
  }

  const toggleReplaceModal = () =>
    setReplaceModalIsOpen((prevState) => !prevState)

  const isPreviewDisabled = !!(
    isGeneratingPreview ||
    !rowIds.length ||
    !sheet.columns ||
    !currentMapping.fieldPairings ||
    getPairingList(currentMapping).length === 0
  )
  const isReplaceDisabled = !!(
    isGeneratingPreview ||
    isSavingMapping ||
    !sheet.columns ||
    !currentMapping.id
  )
  const documentId =
    currentMapping.document?.id || currentMapping.docusign?.templateId
  const isMappingReadyToSave = isReadyToSave(currentMapping)
  const mappingType = getMappingType(currentMapping)

  return (
    <div className='doc-handler'>
      <div className='top-controls'>
        {/* Field to edit/select/format output doc name */}
        <OutputNameField
          field='Filename'
          mapping={currentMapping}
          setOutputNameColumn={setOutputNameColumn}
        />
        <div className='editor-controls'>
          {/* Preview button. Only available for native mappings */}
          {mappingType === MAPPING_TYPES.native && (
            <div className='editor-control'>
              <Button
                data-dd-action-name='smar:doc.handler.preview.btn'
                style={{ padding: '0.58em', width: '5em' }}
                isDisabled={isPreviewDisabled}
                isLoading={isGeneratingPreview}
                appearance='secondary'
                onClick={showPreview}
              >
                {t('docHandler.preview', 'Preview')}
              </Button>
            </div>
          )}

          <SettingsMenu
            toggleReplaceModal={toggleReplaceModal}
            isReplaceDisabled={isReplaceDisabled}
            setIsWizardOpen={setIsWizardOpen}
            setEmailPreviewerOpen={setEmailPreviewerOpen}
            emailPreviewerEnabled={currentMapping && rowIds.length}
          />

          {replaceModalIsOpen && (
            <ReplaceModal
              replaceModalIsOpen={replaceModalIsOpen}
              setReplaceModalIsOpen={setReplaceModalIsOpen}
            />
          )}

          <div className='editor-control'>
            <Button
              data-dd-action-name='smar:doc.handler.save.btn'
              style={{ padding: '0.58em' }}
              isDisabled={!isMappingReadyToSave}
              appearance='primary'
              isLoading={isSavingMapping}
              onClick={saveAndExit}
            >
              {t('docHandler.save', 'Save')}
            </Button>
          </div>
        </div>
      </div>
      <DocumentViewer key={documentId} setFieldPairing={setFieldPairing} />
      {emailPreviewerOpen && (
        <EmailPreviewer isOpen setIsOpen={setEmailPreviewerOpen} />
      )}
    </div>
  )
}

DocHandler.propTypes = {
  setFieldPairing: PropTypes.func.isRequired,
  setOutputNameColumn: PropTypes.func.isRequired,
  setIsWizardOpen: PropTypes.func.isRequired,
  setIsEditorOpen: PropTypes.func.isRequired
}

export default DocHandler
